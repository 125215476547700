<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
    <mat-toolbar>
      <img id="logo" src="../../assets/logo.png" />
    </mat-toolbar>
    <mat-nav-list>
      <!--para agregar un menú/modulo más, hay que hacerlo en la clase de este componente-->
      <a *ngFor="let link of links" mat-list-item [routerLink]="link.routerLink"
        routerLinkActive="active">{{link.label}}</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <!--para se se abarque todo el alto del viewport...-->
    <div id="sidenav-content" fxLayout="column" fxFlex>
      <mat-toolbar>
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
          *ngIf="isHandset$ | async">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <!--hice esto para que cuando se le haga click a uno de los links del menú, en la parte superior muestre el mismo
            label que el del menú (es fea esta forma, porque genera unos links al pedo quedan quedan escondidos, pero no se me ocurrió
            una alternativa más limpia o no tan rebuscada)-->
        <div *ngFor="let link of links" routerLinkActive #rla="routerLinkActive">
          <div *ngIf="rla.isActive">{{link.label}}</div>
          <a [routerLink]="link.routerLink"></a>
        </div>
      </mat-toolbar>
      <!--...y este alto pueda ser "heredado" por los div's hijos. Lo hice para 
          que al cargar la tabla de productos, el scroll aparezca solo en la tabla
          y no en todo este mat-sidenav-content, haciendo que se scrollee todo lo superior-->
      <div id="router-container" fxFlex>
        <router-outlet></router-outlet>
      </div>
      <!--Margen debajo de todo-->
      <div fxFlex="10px"></div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>