import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientesCuentaComponent } from './clientes/clientes-cuenta/clientes-cuenta.component';
import { ClientesGestionComponent } from './clientes/clientes-gestion/clientes-gestion.component';
import { ClientesMainComponent } from './clientes/clientes-main/clientes-main.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ProductosGestionComponent } from './productos/productos-gestion/productos-gestion.component';
import { ProductosMainComponent } from './productos/productos-main/productos-main.component';
import { ProductosMasivoComponent } from './productos/productos-masivo/productos-masivo.component';

const routes: Routes = [
  {
    path: 'productos', component: ProductosMainComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'gestion' },
      { path: 'gestion', component: ProductosGestionComponent },
      { path: 'reportes', component: NotFoundComponent },
      { path: 'masiva', component: ProductosMasivoComponent }
    ],
  },
  {
    path: 'clientes', component: ClientesMainComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'cuenta' },
      { path: 'cuenta', component: ClientesCuentaComponent },
      { path: 'gestion', component: ClientesGestionComponent },
      { path: 'saldos', component: NotFoundComponent },
    ]
  },
  { path: 'ventas', component: NotFoundComponent },
  //Para establecer cual va a ser el módulo seleccionado al ingresar al app, 
  //hay que editar el siguiente objeto
  { path: '', pathMatch: 'full', redirectTo: '/productos/gestion' },
  { path: "**", component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
