import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) { }

  showSuccess(message: string) {
    this.snackBar.open(message, "OK", { panelClass: ['style-success'] });
  }

  showError(message: string) {
    this.snackBar.open(message, "Cerrar");
  }

  showApiError() {
    this.snackBar.open("Se ha producido un error al conectarse al API", "Cerrar");
  }
}
