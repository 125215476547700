import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-navegador-horizontal',
  templateUrl: './navegador-horizontal.component.html',
  styleUrls: ['./navegador-horizontal.component.css']
})
export class NavegadorHorizontalComponent implements OnInit {

  @Input() links : {link:string, routerLink:string}[]  = []

  activeLink = this.links[0];
  
  constructor() { }

  ngOnInit(): void {
  }

}
