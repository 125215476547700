import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/shared/snack-bar-service.service';
import { Cliente } from '../cliente.interface';
import { ClientesService } from '../clientes.service';

@Component({
  selector: 'app-cliente-dialog',
  templateUrl: './cliente-dialog.component.html',
  styleUrls: ['./cliente-dialog.component.css']
})
export class ClienteDialogComponent implements OnInit {

  /**Define si se está realizando la llamada a backend para guardar o editar el cliente. Se utiliza para mostrar un spinner  */
  procesandoCliente: boolean = false;
  /**Nombre del cliente que se muestra en el título, cuando se está editando uno */
  nombreCliente: string = '';
  /**Define si se está cargando el dialog, es decir, esperando que llegue la respuesta de backend que trae el cliente para modificarlo */
  cargandoDialog: boolean = true;

  formCliente = this.fb.group({
    id: [''],
    nombre: ['', Validators.required],
    direccion: [''],
    telefono: [''],
    limiteCredito: [0],
    razonSocial: [''],
    tipoIdentificador: [''],
    identificador: [''],
    situacionIva: ['']
  });

  constructor(private fb: FormBuilder,
    private dialogRef: MatDialogRef<ClienteDialogComponent>,
    private clientesService: ClientesService,
    private snackService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public dialogData: { esModificacion: boolean } | undefined) { }

  ngOnInit(): void {
  }

  /** Funcion para setear el cliente para modificarlo*/
  setCliente(cliente: Cliente) {
    this.nombreCliente = cliente.nombre;
    this.formCliente.setValue(cliente);
    this.cargandoDialog = false;
  }

  /**Cierra el dialog */
  onCloseClick() {
    this.dialogRef.close();
  }

  /**Envía la modificación a Backend */
  onModifyClick() {
    if (!this.formCliente.valid) {
      return;
    }
    this.procesandoCliente = true;
    this.clientesService.editCliente(this.formCliente.value).subscribe({
      next: c => {
        this.snackService.showSuccess(`Cliente modificado correctamente`);
        this.dialogRef.close(true);
      },
      error: (err) => {
        console.log(err);
        this.procesandoCliente = false;
        this.snackService.showError(err);
      }
    });
  }

  /**Envía la creación a Backend */
  onCreateClick() {
    if (!this.formCliente.valid) {
      return;
    }
    const cliente: Cliente = this.formCliente.value;
    this.procesandoCliente = true;
    this.clientesService.createCliente(cliente).subscribe({
      next: c => {
        this.snackService.showSuccess(`Cliente "${c.nombre}" creado exitosamente`);
        this.dialogRef.close(true);
      },
      error: (err) => {
        console.log(err);
        this.procesandoCliente = false;
        this.snackService.showError(err);
      }
    });
  }
}