<div fxLayoutGap="10px">
    <button mat-raised-button (click)="openNuevoProductoDialog()">
        <mat-icon>add</mat-icon>Nuevo producto
    </button>
    <button mat-raised-button (click)="mostrarTodos()">
        <mat-icon>library_books</mat-icon>Mostrar todos
    </button>
    <button mat-raised-button (click)="descargarTodos()">
        <mat-icon>download</mat-icon>Descargar todos
    </button>
</div>