<!-- si es modificacion, se usa #modificarClienteTemplate, si no #nuevoClienteTemplate -->
<div *ngIf="dialogData?.esModificacion; then modificarClienteTemplate; else nuevoClienteTemplate"></div>

<!-- template de nuevo cliente -->
<ng-template #nuevoClienteTemplate>
    <h1 mat-dialog-title>Nuevo cliente</h1>
    <ng-container *ngTemplateOutlet="formularioTemplate"></ng-container>
</ng-template>

<!-- template de modificar cliente -->
<ng-template #modificarClienteTemplate>
    <!-- si se está cargando el cliente, se muestra el spinner; caso contrario el formulario -->
    <ng-container *ngIf="cargandoDialog then cargandoDialogTemplate else editarClienteTemplate"></ng-container>

    <!-- template con el spinner -->
    <ng-template #cargandoDialogTemplate>
        <mat-progress-spinner mode="indeterminate" diameter=20></mat-progress-spinner>
    </ng-template>

    <!-- template con el formulario -->
    <ng-template #editarClienteTemplate>
        <h1 mat-dialog-title>Editando {{ nombreCliente }}</h1>
        <ng-container *ngTemplateOutlet="formularioTemplate"></ng-container>
    </ng-template>
</ng-template>

<!-- el cuerpo del formulario se usa tanto para crear como para modificar-->
<ng-template #formularioTemplate>
    <div>
        <p>Datos personales</p>
        <div mat-dialog-content>
            <div [formGroup]="formCliente">
                <div fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field appearance="fill">
                        <mat-label>Nombre</mat-label>
                        <input matInput formControlName="nombre">
                        <mat-error *ngIf="formCliente.controls['nombre'].hasError('required')"> Debe ingresar un nombre
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex appearance="fill">
                        <mat-label>Dirección</mat-label>
                        <input matInput formControlName="direccion">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field fxFlex appearance="fill">
                        <mat-label>Teléfono</mat-label>
                        <input matInput formControlName="telefono">
                    </mat-form-field>
                    <mat-form-field fxFlex appearance="fill">
                        <mat-label>Límite de crédito</mat-label>
                        <input matInput formControlName="limiteCredito" />
                    </mat-form-field>
                </div>
                <div fxLayout="row">
                </div>
                <p>Impositivas</p>
                <div fxLayout="row">
                    <mat-form-field fxFlex appearance="fill">
                        <mat-label>Razon social</mat-label>
                        <input matInput formControlName="razonSocial" />
                    </mat-form-field>
                </div>
                <div fxLayout="row">
                    <mat-form-field fxFlex appearance="fill">
                        <mat-label>Situación frente a IVA</mat-label>
                        <!-- <input matInput formControlName="situacionIva" /> -->
                        <mat-select formControlName="situacionIva">
                            <mat-option value="0">Monotributo</mat-option>
                            <mat-option value="1">Responsable Inscripto</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field fxFlex appearance="fill">
                        <mat-label>Tipo de identificador</mat-label>
                        <mat-select formControlName="tipoIdentificador">
                            <mat-option value="0">DNI</mat-option>
                            <mat-option value="1">CUIT/CUIL</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex appearance="fill">
                        <mat-label>Identificador</mat-label>
                        <input matInput formControlName="identificador" />
                    </mat-form-field>
                </div>

            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="onCloseClick()">Volver</button>
        <button mat-button (click)="onCreateClick()" *ngIf="!dialogData?.esModificacion">Crear</button>
        <button mat-button (click)="onModifyClick()" *ngIf="dialogData?.esModificacion">Guardar</button>
        <mat-progress-spinner *ngIf="procesandoCliente" mode="indeterminate" diameter=20></mat-progress-spinner>
    </div>
</ng-template>