<!-- si es modificacion, se usa #modificarProductoTemplate, si no #nuevoProductoTemplate -->
<div *ngIf="dialogData?.esModificacion; then modificarProductoTemplate; else nuevoProductoTemplate"></div>

<!-- template de nuevo producto -->
<ng-template #nuevoProductoTemplate>
    <h1 mat-dialog-title>Nuevo producto</h1>
    <ng-container *ngTemplateOutlet="formularioTemplate"></ng-container>
</ng-template>

<!-- template de modificar producto -->
<ng-template #modificarProductoTemplate>
    <!-- si se está cargando el producto, se muestra el spinner; caso contrario el formulario -->
    <ng-container *ngIf="cargandoDialog then cargandoDialogTemplate else editarProductoTemplate"></ng-container>

    <!-- template con el spinner -->
    <ng-template #cargandoDialogTemplate>
        <mat-progress-spinner mode="indeterminate" diameter=20></mat-progress-spinner>
    </ng-template>

    <!-- template con el formulario -->
    <ng-template #editarProductoTemplate>
        <h1 mat-dialog-title>Editando {{ nombreProducto }}</h1>
        <ng-container *ngTemplateOutlet="formularioTemplate"></ng-container>
    </ng-template>
</ng-template>

<!-- el cuerpo del formulario se usa tanto para crear como para modificar-->
<ng-template #formularioTemplate>
    <div>
        <p>Datos del producto</p>
        <div mat-dialog-content>
            <div [formGroup]="formProducto">
                <div fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field fxFlex="20" appearance="fill">
                        <mat-label>Código</mat-label>
                        <input matInput formControlName="codigo">
                        <mat-error *ngIf="formProducto.controls['codigo'].hasError('codigoExists')">El código está en
                            uso
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex appearance="fill">
                        <mat-label>Nombre</mat-label>
                        <input matInput formControlName="nombre">
                        <mat-error *ngIf="formProducto.controls['nombre'].hasError('required')">Debe ingresar un nombre
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row">
                    <mat-form-field fxFlex appearance="fill">
                        <mat-label>Sumario</mat-label>
                        <input matInput formControlName="sumario">
                    </mat-form-field>
                </div>
                <div fxLayout="row">
                    <mat-form-field fxFlex appearance="fill">
                        <mat-label>Descripción</mat-label>
                        <textarea matInput formControlName="descripcion"></textarea>
                    </mat-form-field>
                </div>
                <div fxLayout="row">
                    <mat-form-field fxFlex appearance="fill">
                        <mat-label>Departamento</mat-label>
                        <input type="text" matInput formControlName="departamento" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayDepto">
                            <mat-option *ngFor="let departamento of filteredDepartamentos$| async"
                                [value]="departamento">
                                {{departamento.nombre}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-progress-bar mode="indeterminate"
                            *ngIf="cargandoDepartamentos && !errorAlCargarDepartamentos">
                        </mat-progress-bar>
                    </mat-form-field>
                </div>
                <p>Precios</p>
                <div fxLayout="row">
                    <mat-form-field appearance="fill">
                        <mat-label>Precio costo</mat-label>
                        <input matInput formControlName="precioCosto" (input)="onCostoInput()">
                        <span matSuffix>$</span>
                        <mat-error *ngIf="formProducto.hasError('price', 'precioCosto')">Debe ingresar un precio válido
                        </mat-error>
                        <mat-error *ngIf="formProducto.hasError('min', 'precioCosto')">Debe ingresar un precio mínimo
                            de 0
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field appearance="fill">
                        <mat-label>Porcentaje venta</mat-label>
                        <input matInput formControlName="porcentajePrecioVenta" (input)="onPorcentajeVentaInput()">
                        <span matSuffix>%</span>
                        <mat-error *ngIf="formProducto.hasError('required', 'precioVenta')">Debe ingresar un precio de
                            venta
                        </mat-error>
                        <mat-error *ngIf="formProducto.hasError('price', 'precioVenta')">Debe ingresar un precio válido
                        </mat-error>
                        <mat-error *ngIf="formProducto.hasError('min', 'precioVenta')">Debe ingresar un precio mínimo
                            de 0.01
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Precio venta</mat-label>
                        <input matInput formControlName="precioVenta" (input)="verificarDiferenciaPrecioVentaCalculado()">
                        <span matSuffix>$</span>
                        <mat-error *ngIf="formProducto.hasError('required', 'precioVenta')">Debe ingresar un precio de
                            venta
                        </mat-error>
                        <mat-error *ngIf="formProducto.hasError('price', 'precioVenta')">Debe ingresar un precio válido
                        </mat-error>
                        <mat-error *ngIf="formProducto.hasError('min', 'precioVenta')">Debe ingresar un precio mínimo
                            de 0.01
                        </mat-error>
                    </mat-form-field>
                    <mat-icon fxFlexAlign="center" *ngIf="precioVentaNoCorresponde" class="warning"
                        matTooltip="El precio de venta especificado difiere con el calculado en base al porcentaje" aria-hidden="false" aria-label="Example home icon">warning
                    </mat-icon>
                </div>
                <div fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field appearance="fill">
                        <mat-label>Porcentaje mayoreo</mat-label>
                        <input matInput formControlName="porcentajePrecioMayoreo" (input)="onPorcentajeMayoreoInput()">
                        <span matSuffix>%</span>
                        <mat-error *ngIf="formProducto.hasError('price', 'precioMayoreo')">Debe ingresar un precio
                            válido</mat-error>
                        <mat-error *ngIf="formProducto.hasError('min', 'precioMayoreo')">Debe ingresar un precio mínimo
                            de 0</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Precio mayoreo</mat-label>
                        <input matInput formControlName="precioMayoreo" (input)="verificarDiferenciaPrecioMayoreoCalculado()">
                        <span matSuffix>$</span>
                        <mat-error *ngIf="formProducto.hasError('price', 'precioMayoreo')">Debe ingresar un precio
                            válido</mat-error>
                        <mat-error *ngIf="formProducto.hasError('min', 'precioMayoreo')">Debe ingresar un precio mínimo
                            de 0</mat-error>
                    </mat-form-field>
                    <mat-icon fxFlexAlign="center" *ngIf="precioMayoreoNoCorresponde" class="warning"
                        matTooltip="El precio de mayoreo especificado difiere con el calculado en base al porcentaje" aria-hidden="false" aria-label="Example home icon">warning
                    </mat-icon>
                </div>
                <!-- <p>Inventario</p>
            <mat-form-field appearance="fill">
                <mat-label>Cantidad actual</mat-label>
                <input matInput formControlName="cantidadActual">
                <mat-error *ngIf="formProducto.controls['cantidadActual'].hasError('integer')">Debe ingresar número
                    entero</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Mínimo</mat-label>
                <input matInput formControlName="cantidadMinima">
                <mat-error *ngIf="formProducto.controls['cantidadMinima'].hasError('integer')">Debe ingresar número
                    entero</mat-error>
            </mat-form-field> -->
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="onCloseClick()">Volver</button>
        <button mat-button (click)="onCreateClick()" *ngIf="!dialogData?.esModificacion">Crear</button>
        <button mat-button (click)="onModifyClick()" *ngIf="dialogData?.esModificacion">Guardar</button>
        <mat-progress-spinner *ngIf="procesandoProducto" mode="indeterminate" diameter=20></mat-progress-spinner>
    </div>
</ng-template>