import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductoDialogComponent } from '../producto-dialog/producto-dialog.component';

@Component({
  selector: 'app-producto-actions',
  templateUrl: './producto-actions.component.html',
  styleUrls: ['./producto-actions.component.css']
})
export class ProductoActionsComponent implements OnInit {

  @Output() newProductoEvent = new EventEmitter();
  @Output('mostrarTodosClick') mostrarTodosEvent = new EventEmitter();

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openNuevoProductoDialog() {
    const dialogRef = this.dialog.open(ProductoDialogComponent);
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe({
      next: (createSuccess) => {
        if (createSuccess === true) {
          this.newProductoEvent.emit();
        }
      }
    });
  }

  mostrarTodos() {
    this.mostrarTodosEvent.emit();
  }

  descargarTodos() {
    alert('Este botón es para descargar todos los productos en un Excel');
  }
}
