<div fxLayout="column" fxFill fxLayoutGap="10px">
    <div fxLayoutGap="10px">
        <button mat-raised-button (click)="openNuevoClienteDialog()">
            <mat-icon>add</mat-icon>Nuevo cliente
        </button>
        <button mat-raised-button (click)="mostrarTodos()">
            <mat-icon>library_books</mat-icon>Mostrar todos
        </button>
        <button mat-raised-button (click)="descargarTodos()">
            <mat-icon>download</mat-icon>Descargar todos
        </button>
    </div>
    <mat-form-field class="example-full-width">
        <mat-label>Filtro</mat-label>
        <input matInput placeholder="Filtrar por nombre" [(ngModel)]="filterInputValue" (input)="onFiltroChange()"
            autocomplete="off" />
    </mat-form-field>
    <mat-progress-spinner *ngIf="obteniendoClientes" mode="indeterminate" diameter=25></mat-progress-spinner>
    <!--El overflow:auto hace que como la tabla sobrepase el tamaño del div, se muestre un scroll-->
    <div id="table-container" fxFlex style="overflow: auto;">
        <div *ngIf="clientesNoEncontrados">No se han encontrado clientes con el filtro ingresado</div>
        <app-generic-table *ngIf="clientesObtenidos && clientes.length > 0" [dataSource]="clientes"
            [columns]="tableColumns" [expandableTemplate]="detallesClientes" fieldDescribingItemName="nombre"
            (edit)="openEditarClienteDialog($event)" (delete)="openBorrarClienteDialog($event)">
        </app-generic-table>
        <ng-template #detallesClientes let-cliente>
            <div>
                <p>Razón social: <b>{{cliente.razonSocial}}</b></p>
                <p>Situacion frente a IVA: <b>Responsable inscripto</b></p>
                <p>CUIT: <b>{{cliente.identificador}}</b></p>
            </div>
        </ng-template>
    </div>
</div>