import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AppConfigService } from '../app-config.service';
import { Departamento } from './departamento';

@Injectable({
  providedIn: 'root'
})
export class DepartamentosService {


  constructor(private http: HttpClient,
    private appConfig: AppConfigService) { }

  getDepartamentos(): Observable<Departamento[]> {
    return this.http.get<Departamento[]>(`${this.appConfig.apiBaseUrl}/productos/departamentos`);
  }
}
