import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-productos-main',
  templateUrl: './productos-main.component.html',
  styleUrls: ['./productos-main.component.css'],

})
export class ProductosMainComponent implements OnInit {

  links = [
    { link: "Gestión", routerLink: "gestion" },
    { link: "Reportes", routerLink: "reportes" },
    { link: "Carga masiva", routerLink: "masiva" },
  ];
  activeLink = this.links[0];

  constructor() { }

  ngOnInit(): void {

  }
}
