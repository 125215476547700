import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clientes-main',
  templateUrl: './clientes-main.component.html',
  styleUrls: ['./clientes-main.component.css']
})
export class ClientesMainComponent implements OnInit {

  links = [
    { link: "Estado de cuenta", routerLink: "cuenta" },
    { link: "Gestión", routerLink: "gestion" },
    { link: "Reporte de saldos", routerLink: "saldos" },
  ];
  activeLink = this.links[0];

  constructor() { }

  ngOnInit(): void {
  }

}
