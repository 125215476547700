import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor,HttpRequest,HttpResponse,HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from "rxjs";
import {catchError, map} from 'rxjs/operators';
import { Router } from "@angular/router";
 
@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
    
  constructor(public router: Router) {
  }
 
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
    return next.handle(req).pipe(
      catchError((error) => {
        let message;
        if (error instanceof HttpErrorResponse){
          if (error.status == 500) {
            message = "Se ha producido un error interno en el API";
          } else if (error.status == 400) {
            message = "Se ha producido un error de validación de los datos";
          }
        }
        console.error(error);
        return throwError(message);
      })
    )
  }
}