import { FormControl, FormGroup } from '@angular/forms';

export abstract class FormsService {

  static markAllFieldsAsDirty(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control) {
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
          FormsService.markAllFieldsAsDirty(control);
        }
      }
    });
  }
}
