import { AbstractControl, ValidatorFn } from "@angular/forms";

export class SharedValidators {
    static integer(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const isInteger = RegExp(/^[0-9]+$/).test(control.value);
            return !isInteger ? { integer: { value: control.value } } : null;
        };
    }

    static price(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (typeof control.value === "number") {
                return control.value < 0 ? { price: { value: control.value } } : null;
            }
            if (!control.value.trim()) {
                return null;
            }
            const isValidPrice = RegExp(/^[0-9]+([.][0-9]+)?$/).test(control.value);
            return !isValidPrice ? { price: { value: control.value } } : null;
        };
    }
}