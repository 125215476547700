import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

/**Muestra una tabla genérica. Puede configurarse para que muestre un contenido desplegable al hacer click en cada fila.
 * También, en la última columna, se muestran un botón de modificacion y otro de eliminacion. Útil para los CRUDs
*/
@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)'))
    ])
  ]
})
export class GenericTableComponent<T> implements OnInit {
  /**Array con los objetos que componen cada fila */
  @Input() dataSource!: T[];
  /**Las columnas de la tabla, donde 'name' se corresponde con el nombre de alguna propiedad del objeto
   * de la fila.
   */
  @Input() columns!: { name: string, label: string }[]
  /**Template que se muestra al desplegar una fila */
  @Input() expandableTemplate!: TemplateRef<any>;
  /**El evento que se produce cuando se hace click en el botón 'modificar' de una fila. Emite el objeto de la fila */
  @Output('edit') editEvent = new EventEmitter<T>();
  /**El evento que se produce cuando se hace click en el botón 'borrar' de una fila. Emite el objeto de la fila */
  @Output('delete') deleteEvent = new EventEmitter<T>();
  /**Nombre la propiedad que describe el nombre del objeto, para utilizarse en los tooltips de los botones 'modificar' 
   * y eliminar. Ej. 'Modificar Papel OCB' */
  @Input() fieldDescribingItemName: string = '';

  /**Columnas a mostrar, se setea en el constructor */
  columnsToDisplay!: string[];
  /**Item actualmente expandido */
  expandedItem!: T;

  constructor() { }

  ngOnInit(): void {
    this.columnsToDisplay = this.columns.map(c => c.name);
    this.columnsToDisplay.push('actions');
  }

  onEditClicked(item: T, event: MouseEvent) {
    event.cancelBubble = true; // Para que no se muestren los detalles por hacer click en una fila
    this.editEvent.emit(item);
  }

  onDeleteClicked(item: T, event: MouseEvent) {
    event.cancelBubble = true; // Para que no se muestren los detalles por hacer click en una fila
    this.deleteEvent.emit(item);
  }
}