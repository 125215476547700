import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, of } from "rxjs";
import { DialogInformationComponent } from "./dialog-information/dialog-information.component";
import { DialogQuestionComponent } from "./dialog-question/dialog-question.component";

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    constructor(private dialog: MatDialog) { }

    openQuestionDialog(title: string, question: string) {
        const dialogRef = this.dialog.open(DialogQuestionComponent);
        const componentInstance = dialogRef.componentInstance;
        componentInstance.question = question;
        componentInstance.title = title;
        return dialogRef;
    }

    /**
     * Abre un dialog de pregunta, pero que antes de mostrarse, debe ejecutar una tarea
     * @param taskBeforeDisplay La tarea a ejecutar. Debe devolver un objeto con las propiedades "question" y "title"
     * @param taskOnAffirmativeSelection La tarea a ejecutar cuando se le da click a "Si"
     */
    openQuestionDialogAsync(taskBeforeDisplay: Observable<{ question: string, title: string }>,
                            taskOnAffirmativeSelection: Observable<any> = of()
        ) {
        //Se abre el dialog
        const dialogRef = this.dialog.open(DialogQuestionComponent);
        const componentInstance = dialogRef.componentInstance;
        componentInstance.isAsync = true;
        componentInstance.affirmativeTask = taskOnAffirmativeSelection;
        //Se ejecuta la tarea
        taskBeforeDisplay.subscribe({
            next: (result) => {
                //Cuando termina, setea las propiedades
                componentInstance.question = result.question;
                componentInstance.title = result.title;
                //Eso dispara que se muestre el cuerpo del dialog
                componentInstance.hasCompletedTask = true;
            }
        });
        return dialogRef;
    }

    openInformationDialog(title: string, information: string) {
        const dialogRef = this.dialog.open(DialogInformationComponent);
        const componentInstance = dialogRef.componentInstance;
        componentInstance.information = information;
        componentInstance.title = title;
        return dialogRef;
    }
}