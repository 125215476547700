import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientesMainComponent } from './clientes-main/clientes-main.component';
import { RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { ClientesGestionComponent } from './clientes-gestion/clientes-gestion.component';
import { ClientesCuentaComponent } from './clientes-cuenta/clientes-cuenta.component';
import { SharedModule } from '../shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { ClienteDialogComponent } from './cliente-dialog/cliente-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    ClientesMainComponent,
    ClientesGestionComponent,
    ClientesCuentaComponent,
    ClienteDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatTabsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatButtonModule,
    MatDialogModule,
    FlexLayoutModule,
    SharedModule,
    RouterModule
  ]
})
export class ClientesModule { }
