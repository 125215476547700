<div id="productos-main-container" fxLayout="column" fxLayoutGap="10px" fxFill>
    <nav mat-tab-nav-bar>
        <a mat-tab-link *ngFor="let link of links" (click)="activeLink = link" [active]="activeLink == link"
            [routerLink]="link.routerLink"> {{link.link}} </a>
    </nav>
    <!--si acá le pongo fxFill (en lugar de fxFlex), agrega un scroll afuera de la tabla-->
    <div fxLayout="row" fxFlex>
        <!--solo para agregar un margen izquierdo de 20px-->
        <div fxFlex="20px"></div>
        <div fxFlex>
            <router-outlet></router-outlet>
        </div>
        <!--solo para agregar un margen izquierdo de 20px-->
        <div fxFlex="20px"></div>
    </div>
</div>