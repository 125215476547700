import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { DialogInformationComponent } from './dialogs/dialog-information/dialog-information.component';
import { DialogQuestionComponent } from './dialogs/dialog-question/dialog-question.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GenericTableComponent } from './components/generic-table/generic-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

//-----
import { NavegadorHorizontalComponent } from '../navegador-horizontal/navegador-horizontal.component'
// Requeridos por NavegadorHorizontalComponent
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
//-----

@NgModule({
  declarations: [
    DialogQuestionComponent,
    DialogInformationComponent,
    NavegadorHorizontalComponent,
    GenericTableComponent
  ],
  exports: [
    NavegadorHorizontalComponent,
    GenericTableComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatTableModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    RouterModule
  ]
})
export class SharedModule { }
