import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { DialogService } from 'src/app/shared/dialogs/dialog.service';
import { SnackBarService } from 'src/app/shared/snack-bar-service.service';
import { ClienteDialogComponent } from '../cliente-dialog/cliente-dialog.component';
import { Cliente } from '../cliente.interface';
import { ClientesService } from '../clientes.service';

@Component({
  selector: 'app-clientes-gestion',
  templateUrl: './clientes-gestion.component.html',
  styleUrls: ['./clientes-gestion.component.css']
})
export class ClientesGestionComponent implements OnInit {

  clientes: Cliente[] = [];
  clientesObtenidos: boolean = false;
  obteniendoClientes: boolean = false;
  tableColumns = [
    { name: 'nombre', label: 'Nombre' },
    { name: 'direccion', label: 'Dirección' },
    { name: 'telefono', label: 'Teléfono' },
    { name: 'limiteCredito', label: 'Límite' }
  ];
  filter$ = new Subject<string>();
  filterInputValue: string = '';
  clientesNoEncontrados: boolean = false;
  constructor(private clientesService: ClientesService,
    private snackService: SnackBarService,
    private dialog: MatDialog,
    private dialogService: DialogService) { }

  ngOnInit(): void {
    this.filter$.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe({
        next: () => this.cargarClientes(true)
      });
  }

  cargarClientes(checkFilterLength: boolean) {
    this.clientesObtenidos = false;
    this.obteniendoClientes = true;
    if (!checkFilterLength || (this.filterInputValue && this.filterInputValue.length > 1)) {
      this.clientesService.getClientes(this.filterInputValue).subscribe({
        next: (result) => {
          this.clientesNoEncontrados = result.length === 0;
          this.clientes = result;
          this.clientesObtenidos = true;
          this.obteniendoClientes = false;
        },
        error: () => {
          this.obteniendoClientes = false;
          this.snackService.showApiError();
        }
      });
    }
    else {
      this.clientes = [];
      this.clientesObtenidos = true;
      this.obteniendoClientes = false;
    }
  }

  openNuevoClienteDialog() {
    const dialogRef = this.dialog.open(ClienteDialogComponent);
    dialogRef.disableClose = true;
  }

  openEditarClienteDialog(cliente: Cliente) {
    //Se abre el dialog para crear cliente. Como esModificacion=true, sólo va a mostrar un spinner y
    // el dialog se queda esperando a que se le setee un cliente para mostrar el formulario
    let dialogRef = this.dialog.open(ClienteDialogComponent, { data: { esModificacion: true } });
    dialogRef.afterClosed().subscribe(
      value => {
        if (value) {
          this.cargarClientes(true);
        }
      });
    this.clientesService.getCliente(cliente.id).subscribe({
      next: c => {
        //Se setea el cliente y es cuando se muestra el form. El '?' está porque si se abre y se cierra inmediatamente el dialog
        //antes que termine esta tarea, componentInstance va a estar destruido, dando un error
        dialogRef.componentInstance?.setCliente(c);
      }
    });
  }

  openBorrarClienteDialog(cliente: Cliente) {
    let nombreCliente = '';
    // taskBeforeDisplay es un Observable que sirve para el openQuestionDialogAsync, para que 
    // espere (mostrando un spinner) a que se obtenga el cliente desde backend. Si es exitoso, muestra el dialog con titulo y cuerpo
    let taskBeforeDisplay = this.clientesService.getCliente(cliente.id).pipe(
      tap(c => nombreCliente = c.nombre),
      map(c => { return { title: 'Clientes', question: `¿Desea continuar con la eliminacion del cliente ${c.nombre}?` } })
    );

    //taskOnAffirmativeSelection es un Observable, que se va a ejecutar cuando se le da click a "Si" (para querer eliminar el cliente)
    //entonces muestra el spinner hasta que termina con la petición a backend
    let taskOnAffirmativeSelection = this.clientesService.deleteCliente(cliente.id).pipe(
      tap(_ => {
        this.snackService.showSuccess(`El cliente ${nombreCliente} ha sido eliminado correctamente`);
        this.cargarClientes(true);
      })
    );

    this.dialogService.openQuestionDialogAsync(taskBeforeDisplay, taskOnAffirmativeSelection);
  }

  mostrarTodos() {
    this.filterInputValue = '';
    this.cargarClientes(false);
  }

  onFiltroChange() {
    this.clientesNoEncontrados = false;
    this.filter$.next(this.filterInputValue);
  }

  descargarTodos() {
    alert('Este botón es para descargar todos los clientes en un Excel');
  }
}
