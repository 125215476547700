<table mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <!--Se definen las columnas (segun la definición de 'columns') y los valores dentro de ellas en cada fila-->
    <ng-container *ngFor="let column of columns">
        <ng-container [matColumnDef]="column.name">
            <th mat-header-cell *matHeaderCellDef> {{column.label}} </th>
            <td mat-cell *matCellDef="let item"> {{item[column.name]}} </td>
        </ng-container>
    </ng-container>
    <!--Se agrega la columna 'actions', donde se agregan los botones para modificar y borrar-->
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item">
            <button mat-icon-button (click)="onEditClicked(item, $event)"
                matTooltip="Editar {{item[fieldDescribingItemName]}}">
                <mat-icon color="primary">mode_edit</mat-icon>
            </button>
            <button mat-icon-button (click)="onDeleteClicked(item, $event)"
                matTooltip="Eliminar {{item[fieldDescribingItemName]}}">
                <mat-icon color="warn">delete_forever</mat-icon>
            </button>
        </td>
    </ng-container>
    <!--Definición de la columna que se expande cuando se hace click en una fila-->
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let item" [attr.colspan]="columnsToDisplay.length">
            <div class="item-detail" [@detailExpand]="item == expandedItem ? 'expanded' : 'collapsed'">
                <ng-container *ngTemplateOutlet="expandableTemplate; context: {$implicit : item}"></ng-container>
            </div>
        </td>
    </ng-container>
    <!--Muestra los headers-->
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <!--Muestra cada fila-->
    <tr mat-row *matRowDef="let item; columns: columnsToDisplay"
        (click)="expandedItem = expandedItem === item? null : item"
        [class.expanded-row]="expandedItem === item" class="item-row">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
</table>