import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dialog-question',
  templateUrl: './dialog-question.component.html',
  styleUrls: ['./dialog-question.component.css']
})
export class DialogQuestionComponent implements OnInit {

  /**Titulo del dialog */
  title: string = '';
  /**Pregunta que se muestra en el cuerpo */
  question: string = '';
  /**Determina si se debe esperar (mostrar un spinner) una tarea antes de mostrar el dialog */
  isAsync: boolean = false;
  /**Determina si terminó la tarea antes de mostrar el dialog */
  hasCompletedTask: boolean = false;
  /**Tarea que se ejecuta al clickear 'Si' */
  affirmativeTask: Observable<any> | undefined;
  /**Determina si comenzó la tarea que se ejecuta al clickear 'Si' */
  affirmativeTaskStarted: boolean = false;

  constructor(public dialogRef: MatDialogRef<DialogQuestionComponent>) { }

  ngOnInit(): void {
  }

  onYesClicked() {
    if (this.affirmativeTask) {
      this.affirmativeTaskStarted = true;
      this.affirmativeTask.subscribe(
        () => {
          this.dialogRef.close(true);
        }
      );
    }
    else {
      this.dialogRef.close(true);
    }
  }
}