<div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start start">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <!--se reemplaza el botón de seleccionar archivo porque es mas bonito el de Material-->
        <!--el hiddenFileInput.value='' que está dentro de click, es para cuando se sube el mismo archivo dos veces seguidas
            porque se ve que ve que sin cambiar el value, (change) no se dispara-->
        <button mat-raised-button (click)="hiddenFileInput.value='';hiddenFileInput.click()"><mat-icon>description</mat-icon> Seleccionar archivo</button>
        <input #hiddenFileInput type="file" accept=".xls,.xlsx" (change)="onFileSelected($event)" hidden/>
    </div>
    <!--ng-container está bueno para no meter un div, en este caso me rompía lo que sería el flex-->
    <ng-container *ngIf="archivoCargado">
        <div fxFill>
            <mat-card class="success-card">{{mensajeResultado}}</mat-card>
        </div>
        <div fxFill *ngIf="errores.length > 0">
            <mat-card class="error-card">
                <span>Filas con errores:</span>
                <p *ngFor="let error of errores">{{error}}</p>
            </mat-card>
        </div>
    </ng-container>
</div>

<!--Se muestra ni bien se selecciona un archivo, mientras se cargan los productos en Backend-->
<ng-template #spinner>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <mat-spinner diameter="20"></mat-spinner>
        <span>Cargando productos</span>
    </div>
</ng-template>