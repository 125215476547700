<!--Si es async (es decir que tiene que esperar a que termine una tarea) y la tarea no terminó o se comenzó con la tarea cuando se hace click en "Si"
    se muestra el spinner
-->
<mat-progress-spinner *ngIf="isAsync && (!hasCompletedTask || affirmativeTaskStarted)  else questionBodyTemplate"
    mode="indeterminate" diameter=20></mat-progress-spinner>
<ng-template #questionBodyTemplate>
    <h1 mat-dialog-title>{{title}}</h1>
    <div mat-dialog-content>
        <p>{{question}}</p>
    </div>
    <div mat-dialog-actions>
        <button mat-button [mat-dialog-close]="false">No</button>
        <button mat-button (click)="onYesClicked()" cdkFocusInitial>Si</button>
    </div>
</ng-template>