import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http"
import { Cliente } from "./cliente.interface";
import { Observable, of } from "rxjs";
import { AppConfigService } from "../app-config.service";
import { delay, tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class ClientesService {

  clientesMock: Cliente[] = [
    { id: 1, nombre: 'Jose Perez', direccion: 'Lavalle 45', telefono: '2914123926', limiteCredito: 10000, identificador: '', razonSocial: '', situacionIva: 0, tipoIdentificador: 0 },
    { id: 2, nombre: 'Gaston', direccion: 'La plata 456', telefono: '2915123926', limiteCredito: 50000, identificador: '', razonSocial: '', situacionIva: 0, tipoIdentificador: 0 },
    { id: 3, nombre: 'Maria', direccion: 'Alem 1541', telefono: '2916123926', limiteCredito: 40000, identificador: '', razonSocial: '', situacionIva: 0, tipoIdentificador: 0 },
  ];

  constructor(private http: HttpClient,
    private appConfig: AppConfigService) { }

  getClientes(filter: string): Observable<Cliente[]> {
    return of(this.clientesMock.filter(c => c.nombre.indexOf(filter) !== -1)).pipe(
      delay(500)
    );
    return this.http.get<Cliente[]>(`${this.appConfig.apiBaseUrl}/clientes`, { params: { "filtro": filter } });
  }

  createCliente(cliente: Cliente): Observable<Cliente> {
    this.clientesMock.push(cliente);
    return of(cliente).pipe(
      delay(500)
    );
    return this.http.post<Cliente>(`${this.appConfig.apiBaseUrl}/clientes`, cliente);
  }

  editCliente(cliente: Cliente): Observable<Cliente> {
    this.clientesMock = this.clientesMock.filter(c => c.id != cliente.id);
    this.clientesMock.push(cliente);
    return of(cliente).pipe(
      delay(500)
    );
    return this.http.put<Cliente>(`${this.appConfig.apiBaseUrl}/clientes`, cliente);
  }

  getCliente(id: number): Observable<Cliente> {
    const cliente = this.clientesMock.find(c => c.id == id);
    return of(cliente as Cliente).pipe(
      delay(500)
    );
    return this.http.get<Cliente>(`${this.appConfig.apiBaseUrl}/clientes/${id}`);
  }

  deleteCliente(id: number): Observable<boolean> {
    return of(true).pipe(
      delay(500),
      tap(_ => this.clientesMock = this.clientesMock.filter(c => c.id != id))
    );
    return this.http.delete<boolean>(`${this.appConfig.apiBaseUrl}/clientes/${id}`);
  }
}