<!--fxFill hace que este elemento se estire dentro del div padre-->
<div id="productos-gestion-container" fxLayout="column" fxFill fxLayoutGap="10px">
    <app-producto-actions (newProductoEvent)="onNewProducto()" (mostrarTodosClick)="onMostrarTodosClicked()">
    </app-producto-actions>
    <mat-form-field class="example-full-width">
        <mat-label>Filtro</mat-label>
        <input matInput placeholder="Filtrar por código, nombre, sumario, descripción, etc."
            [(ngModel)]="filterInputValue" (input)="onFiltroChange()" autocomplete="off" />
    </mat-form-field>
    <mat-progress-spinner *ngIf="obteniendoProductos" mode="indeterminate" diameter=25></mat-progress-spinner>
    <!--El overflow:auto hace que como la tabla sobrepase el tamaño del div, se muestre un scroll-->
    <div id="table-container" fxFlex style="overflow: auto;">
        <div *ngIf="productosNoEncontrados">No se han encontrado productos con el filtro ingresado</div>
        <app-generic-table *ngIf="productosObtenidos && productos.length > 0" [dataSource]="productos"
            [columns]="tableColumns" (delete)="onDeleteProducto($event)" (edit)="onEditProducto($event)"
            [expandableTemplate]="detallesProducto" fieldDescribingItemName="nombre">
        </app-generic-table>

        <ng-template let-producto #detallesProducto>
            <div>
                <p>Sumario: <b>{{producto.sumario}}</b></p>
                <p>Descripcion: <b>{{producto.descripcion}}</b></p>
                <p>Departamento: <b>{{producto.departamento?.nombre}}<ng-container *ngIf="!producto.departamento">-- Sin
                            departamento --</ng-container></b></p>
                <p>Costo: <b>${{producto.precioCosto.toFixed(2)}}</b></p>
                <p>Precio de mayoreo: <b>${{producto.precioMayoreo.toFixed(2)}}</b></p>
                <p>Porcentaje de precio de mayoreo: <b>{{producto.porcentajePrecioMayoreo}}%</b></p>
                <p>Porcentaje de precio de venta: <b>{{producto.porcentajePrecioVenta}}%</b></p>
                <p>Cantidad mínima: <b>{{producto.cantidadMinima}}</b></p>
            </div>
        </ng-template>
    </div>
</div>