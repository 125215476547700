import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RespuestaMasivo } from 'src/app/shared/model/respuesta-masivo';
import { SnackBarService } from 'src/app/shared/snack-bar-service.service';
import { ProductosService } from '../productos.service';

@Component({
  selector: 'app-productos-masivo',
  templateUrl: './productos-masivo.component.html',
  styleUrls: ['./productos-masivo.component.css']
})
export class ProductosMasivoComponent implements OnInit {

  /**Es el template con el spinner que se va a mostrar mientras se cargan los productos en backend */
  @ViewChild('spinner') spinnerTemplate!: TemplateRef<any>;
  /**Mensaje que se muestra en el card verde */
  mensajeResultado: string = '';
  /**Errores que se muestran en el card rojo */
  errores: string[] = [];
  /**Indica si se terminó de procesar el archivo */
  archivoCargado: boolean = false;

  constructor(private dialog: MatDialog,
    private snackService: SnackBarService,
    private productosService: ProductosService) { }

  ngOnInit(): void {
  }

  onFileSelected(event: any) {
    console.log(event);
    var file: File = event.target.files[0];
    //Para que se dejen de mostrar los errores, porque sería un archivo nuevo
    this.archivoCargado = false;
    //Abre el dialog con el spinner
    const dialogRef = this.dialog.open(this.spinnerTemplate, { disableClose: true });
    this.productosService.masivosProducto(file).subscribe({
      next: (result: RespuestaMasivo[]) => {
        //Le asigno el mensaje del resultado, que viene en la primer posición del array
        this.mensajeResultado = result[0].description;
        //Le quito el primer elemento
        result.shift();
        //Le asigno los errores
        this.errores = result.map(r => r.description);
        //Se cierra el dialog con el spinner
        dialogRef.close();
        this.archivoCargado = true;
        this.snackService.showSuccess("Se completó la carga del archivo");
      },
      error: () => {
        dialogRef.close();
        this.snackService.showApiError();
      }
    });
  }
}
