import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-information',
  templateUrl: './dialog-information.component.html',
  styleUrls: ['./dialog-information.component.css']
})
export class DialogInformationComponent implements OnInit {

  @Input('title') title: string = '';
  @Input('information') information: string = '';
  
  constructor() { }

  ngOnInit(): void {
  }
}