import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { DialogService } from 'src/app/shared/dialogs/dialog.service';
import { SnackBarService } from 'src/app/shared/snack-bar-service.service';
import { Producto } from '../producto';
import { ProductoDialogComponent } from '../producto-dialog/producto-dialog.component';
import { ProductosService } from '../productos.service';

@Component({
  selector: 'app-productos-gestion',
  templateUrl: './productos-gestion.component.html',
  styleUrls: ['./productos-gestion.component.css']
})
export class ProductosGestionComponent implements OnInit, OnDestroy {

  productos: Producto[] = [];
  productosObtenidos: boolean = false;
  obteniendoProductos: boolean = false;
  productosNoEncontrados: boolean = false;
  filterInputValue: string = '';
  filter$ = new Subject<string>();

  tableColumns = [
    { name: 'codigo', label: 'Código' },
    { name: 'nombre', label: 'Nombre' },
    { name: 'precioVenta', label: 'Precio de venta' },
    { name: 'cantidadActual', label: 'Cantidad actual' }
  ];

  constructor(private productosService: ProductosService,
    private snackService: SnackBarService,
    private dialog: MatDialog,
    private dialogService: DialogService) { }

  ngOnDestroy(): void {
    this.filter$.unsubscribe();
  }

  ngOnInit(): void {
    this.filter$.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe({
        next: () => this.cargarProductos(true)
      });
  }

  cargarProductos(checkFilterLength: boolean) {
    this.productosObtenidos = false;
    this.obteniendoProductos = true;
    if (!checkFilterLength || (this.filterInputValue && this.filterInputValue.length > 1)) {
      this.productosService.getProductos(this.filterInputValue).subscribe({
        next: (result) => {
          this.productosNoEncontrados = result.length === 0;
          this.productos = result;
          this.productosObtenidos = true;
          this.obteniendoProductos = false;
        },
        error: () => {
          this.obteniendoProductos = false;
          this.snackService.showApiError();
        }
      });
    }
    else {
      this.productos = [];
      this.productosObtenidos = true;
      this.obteniendoProductos = false;
    }
  }

  onNewProducto() {
    this.cargarProductos(true);
  }

  onDeleteProducto(producto: Producto) {
    var nombreProducto = '';
    // taskBeforeDisplay es un Observable que sirve para el openQuestionDialogAsync, para que 
    // espere (mostrando un spinner) a que se obtenga el producto desde backend. Si es exitoso, muestra el dialog con titulo y cuerpo
    var taskBeforeDisplay = this.productosService.getProducto(producto.id).pipe(
      tap(p => nombreProducto = p.nombre),
      map(p => { return { title: 'Productos', question: `¿Desea continuar con la eliminacion del producto ${p.nombre}?` } })
    );

    //taskOnAffirmativeSelection es un Observable, que se va a ejecutar cuando se le da click a "Si" (para querer eliminar el producto)
    //entonces muestra el spinner hasta que termina con la petición a backend
    var taskOnAffirmativeSelection = this.productosService.deleteProducto(producto.id).pipe(
      tap(_ => {
        this.snackService.showSuccess(`El producto ${nombreProducto} ha sido eliminado correctamente`);
        this.cargarProductos(true);
      })
    );

    this.dialogService.openQuestionDialogAsync(taskBeforeDisplay, taskOnAffirmativeSelection);
  }

  onEditProducto(producto: Producto) {
    //Se abre el dialog para crear producto. Como esModificacion=true, sólo va a mostrar un spinner y
    // el dialog se queda esperando a que se le setee un producto para mostrar el formulario
    var dialogRef = this.dialog.open(ProductoDialogComponent, { data: { esModificacion: true } });
    dialogRef.afterClosed().subscribe(
      value => {
        if (value) {
          this.cargarProductos(true);
        }
      });
    this.productosService.getProducto(producto.id).subscribe({
      next: (producto) => {
        //Se setea el producto y es cuando se muestra el form. El '?' está porque si se abre y se cierra inmediatamente el dialog
        //antes que termine esta tarea, componentInstance va a estar destruido, dando un error
        dialogRef.componentInstance?.setProducto(producto);
      }
    });
  }

  onFiltroChange() {
    this.productosNoEncontrados = false;
    this.filter$.next(this.filterInputValue);
  }

  onMostrarTodosClicked() {
    this.filterInputValue = '';
    this.cargarProductos(false);
  }
}
