import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormsService } from 'src/app/shared/forms.service';
import { SharedValidators } from 'src/app/shared/shared-validators';
import { SnackBarService } from 'src/app/shared/snack-bar-service.service';
import { Departamento } from '../departamento';
import { DepartamentosService } from '../departamentos.service';
import { Producto } from '../producto';
import { IProducto } from '../producto.interface';
import { ProductosService } from '../productos.service';

@Component({
  selector: 'app-producto-dialog',
  templateUrl: './producto-dialog.component.html',
  styleUrls: ['./producto-dialog.component.css']
})
export class ProductoDialogComponent implements OnInit {

  /**Se utiliza para mostrar o no una progress bar que muestra que se estan obteniendo los departamentos */
  cargandoDepartamentos: boolean = true;
  /**Se utiliza para señalar que fallo el obtener departamentos. Por ahora lo unico que hace es ocultar la progress bar */
  errorAlCargarDepartamentos: boolean = false;
  /**Array que contiene los departamentos */
  departamentos: Departamento[] = [];
  /**Observable que se utiliza en el mat-autocomplete de departamentos  */
  filteredDepartamentos$: Observable<Departamento[]> = of();
  /**Define si se está realizando la llamada a backend para guardar o editar el producto. Se utiliza para mostrar un spinner  */
  procesandoProducto: boolean = false;
  /**Define si se está cargando el dialog, es decir, esperando que llegue la respuesta de backend que trae el producto para modificarlo */
  cargandoDialog: boolean = true;
  /**Nombre del producto que se muestra en el título, cuando se está editando un producto */
  nombreProducto: string = '';
  /**Determina si el precio de venta ingresado manualmente es igual al calculado según el porcentaje */
  precioVentaNoCorresponde: boolean = false;
  /**Determina si el precio de mayoreo ingresado manualmente es igual al calculado según el porcentaje */
  precioMayoreoNoCorresponde: boolean = false;

  formProducto = this.fb.group({
    id: [''],
    codigo: [''],
    nombre: ['', Validators.required],
    sumario: [''],
    descripcion: [''],
    departamento: [''],
    precioCosto: [0, [Validators.min(0), SharedValidators.price()]], //Falta testearlo bien
    precioVenta: [0, [Validators.required, Validators.min(0.01), SharedValidators.price()]],
    precioMayoreo: [0, [Validators.min(0), SharedValidators.price()]],
    porcentajePrecioVenta: [0, Validators.min(0)],
    porcentajePrecioMayoreo: [0, Validators.min(0)],
    cantidadActual: [0, SharedValidators.integer()],
    cantidadMinima: [0, SharedValidators.integer()]
  });

  constructor(
    private dialogRef: MatDialogRef<ProductoDialogComponent>,
    private fb: FormBuilder,
    private productosService: ProductosService,
    private departamentosService: DepartamentosService,
    private snackService: SnackBarService,
    /** Informacion del dialog. Sólo se utiliza para indicar si es modificacion en lugar de creación de productos */
    @Inject(MAT_DIALOG_DATA) public dialogData: { esModificacion: boolean } | undefined
  ) { }

  ngOnInit(): void {
    // Se cargan los departamentos
    this.departamentosService.getDepartamentos().subscribe(
      departamentos => {
        this.departamentos = departamentos;
        this.cargandoDepartamentos = false;
      },
      () => {
        this.snackService.showError("No se han podido cargar los departamentos");
        this.errorAlCargarDepartamentos = true;
      }
    );

    //Se inicializa el observable para el mat-autcomplete
    this.filteredDepartamentos$ = this.formProducto.controls['departamento'].valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.departamentos.slice())
      );
  }

  /** Filtro para mat-autocomplete de departamentos */
  private _filter(name: string): Departamento[] {
    const filterValue = name.toLowerCase();
    return this.departamentos.filter(departamento => departamento.nombre.toLowerCase().indexOf(filterValue) === 0);
  }

  /** Funcion para setear el producto para modificarlo*/
  setProducto(producto: Producto) {
    this.nombreProducto = producto.nombre;
    let productoFormData: IProducto = {
      id: producto.id,
      cantidadActual: producto.cantidadActual,
      cantidadMinima: producto.cantidadMinima,
      codigo: producto.codigo,
      departamento: producto.departamento,
      descripcion: producto.descripcion,
      nombre: producto.nombre,
      precioCosto: producto.precioCosto,
      precioMayoreo: producto.precioMayoreo,
      porcentajePrecioVenta: producto.porcentajePrecioVenta,
      porcentajePrecioMayoreo: producto.porcentajePrecioMayoreo,
      precioVenta: producto.precioVenta,
      sumario: producto.sumario
    }
    this.formProducto.setValue(productoFormData);
    this.verificarDiferenciaPrecioMayoreoCalculado();
    this.verificarDiferenciaPrecioVentaCalculado();
    this.cargandoDialog = false;
  }

  /**Cierra el dialog */
  onCloseClick() {
    this.dialogRef.close();
  }

  /**Determina qué se muestra en el mat-autocomplete del departamento */
  displayDepto(departamento: Departamento) {
    return departamento?.nombre;
  }
  /**Verifica que el producto del formulario sea válido y se le setean valores predeterminados */
  validarProducto(): IProducto | null {
    // Si no es válido, se marcan todos los campos como dirty, para que se muestren los errores. Más que nada
    // si se hace click en Crear sin haber cargado ningun dato
    if (!this.formProducto.valid) {
      FormsService.markAllFieldsAsDirty(this.formProducto);
      return null;
    }

    const producto: IProducto = this.formProducto.value;
    //Si es nuevo producto, marco id como undefined para que no se mande en el cuerpo del POST
    if (!this.dialogData?.esModificacion) {
      producto.id = undefined;
    }

    //Si en el campo del departamento se ingresa un valor cualquiera (o que no existe el departamento)
    //se marca como undefined para que no vaya en el POST
    const departamento = this.formProducto.value.departamento;
    if (typeof departamento === 'string') {
      producto.departamento = undefined;
    }

    //Si no se ingresó precioCosto, se le asigna 0
    if (!producto.precioCosto)
      producto.precioCosto = 0;
    //Si no se ingresó precioMayoreo, se le asigna 0
    if (!producto.precioMayoreo)
      producto.precioMayoreo = 0;
    return producto;
  }
  /**Envía la modificación a Backend */
  onModifyClick() {
    //Valida el producto, si es invalido, no guarda nada
    var producto = this.validarProducto();
    if (producto == null) {
      return;
    }

    this.procesandoProducto = true;
    this.productosService.editProducto(this.formProducto.value).subscribe({
      next: (producto) => {
        this.snackService.showSuccess(`Producto modificado correctamente`);
        this.dialogRef.close(true);
      },
      error: (err) => {
        console.log(err);
        this.procesandoProducto = false;
        this.snackService.showError(err);
      }
    });
  }

  /**Envía la creación a Backend */
  onCreateClick() {
    //Valida el producto, si es invalido, no guarda nada
    var producto = this.validarProducto();
    if (producto == null) {
      return;
    }

    this.procesandoProducto = true;
    this.productosService.createProducto(producto).subscribe({
      next: (producto) => {
        this.snackService.showSuccess(`Producto "${producto.nombre}" creado correctamente`);
        this.dialogRef.close(true);
      },
      error: (err) => {
        console.log(err);
        this.procesandoProducto = false;
        this.snackService.showError(err);
      }
    });
  }

  onPorcentajeMayoreoInput() {
    this.recalcularPrecioMayoreo();
  }

  /**Verifica si el precio de venta es igual al calculado segun el porcentaje */
  verificarDiferenciaPrecioVentaCalculado() {
    var precioVenta = this.formProducto.controls.precioVenta.value ?? 0;
    const precioVentaCalculada = this.calcularPrecioVenta();
    this.precioVentaNoCorresponde = precioVenta != precioVentaCalculada;
  }

  onPorcentajeVentaInput() {
    this.recalcularPrecioVenta();
  }

  /**Verifica si el precio de mayoreo es igual al calculado segun el porcentaje */
  verificarDiferenciaPrecioMayoreoCalculado() {
    var precioMayoreo = this.formProducto.controls.precioMayoreo.value ?? 0;
    const precioMayoreoCalculada = this.calcularPrecioMayoreo();
    this.precioMayoreoNoCorresponde = precioMayoreo != precioMayoreoCalculada;
  }

  onCostoInput() {
    this.recalcularPrecioMayoreo();
    this.recalcularPrecioVenta();
  }

  /**Calcula el precio de venta segun el costo y porcentaje ingresados */
  calcularPrecioVenta(): number {
    let floatCosto = Number.parseFloat(this.formProducto.controls.precioCosto.value);
    if (Number.isNaN(floatCosto)) {
      floatCosto = 0;
    }

    let floatPorcentajeVenta = Number.parseFloat(this.formProducto.controls.porcentajePrecioVenta.value);
    if (Number.isNaN(floatPorcentajeVenta)) {
      floatPorcentajeVenta = 0;
    }

    var nuevoPrecioVenta = floatCosto * (1 + floatPorcentajeVenta / 100);
    //Redondea al multiplo de 5 más próximo (para abajo o para arriba)
    nuevoPrecioVenta = Math.round(nuevoPrecioVenta / 5) * 5;
    return nuevoPrecioVenta;
  }

  /**Recalcula el precio de venta segun el costo y el porcentaje ingresados
   * y lo actualiza en el input
   */
  recalcularPrecioVenta() {
    const nuevoPrecioVenta = this.calcularPrecioVenta();
    this.formProducto.controls.precioVenta.setValue(nuevoPrecioVenta);
    this.precioVentaNoCorresponde = false;
  }

  /**Calcula el precio de mayoreo segun el costo y porcentaje ingresado */
  calcularPrecioMayoreo(): number {
    let floatCosto = Number.parseFloat(this.formProducto.controls.precioCosto.value);
    if (Number.isNaN(floatCosto)) {
      floatCosto = 0;
    }

    let floatPorcentajeMayoreo = Number.parseFloat(this.formProducto.controls.porcentajePrecioMayoreo.value);
    if (Number.isNaN(floatPorcentajeMayoreo)) {
      floatPorcentajeMayoreo = 0;
    }

    var nuevoPrecioMayoreo = floatCosto * (1 + floatPorcentajeMayoreo / 100);
    nuevoPrecioMayoreo = Math.round(nuevoPrecioMayoreo * 100) / 100;
    return nuevoPrecioMayoreo;
  }
  /**Recalcula el precio de mayoreo segun el costo y el porcentaje ingresados
   * y lo actualiza en el input
   */
  recalcularPrecioMayoreo() {
    const nuevoPrecioMayoreo = this.calcularPrecioMayoreo();
    this.formProducto.controls.precioMayoreo.setValue(nuevoPrecioMayoreo);
    this.precioMayoreoNoCorresponde = false;
  }
}
