import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http"
import { Producto } from "./producto";
import { Observable, of } from "rxjs";
import { AppConfigService } from "../app-config.service";
import { IProducto } from "./producto.interface";

@Injectable({
    providedIn: 'root',
})
export class ProductosService {

    constructor(private http: HttpClient,
        private appConfig: AppConfigService) { }

    getProductos(filter: string): Observable<Producto[]> {
        return this.http.get<Producto[]>(`${this.appConfig.apiBaseUrl}/productos`, { params: { "filtro": filter } });
    }

    createProducto(producto: IProducto): Observable<Producto> {
        return this.http.post<Producto>(`${this.appConfig.apiBaseUrl}/productos`, producto);
    }

    editProducto(producto: IProducto): Observable<Producto> {
        return this.http.put<Producto>(`${this.appConfig.apiBaseUrl}/productos`, producto);
    }

    getProducto(id: number): Observable<Producto> {
        return this.http.get<Producto>(`${this.appConfig.apiBaseUrl}/productos/${id}`);
    }

    deleteProducto(id: number): Observable<boolean> {
        return this.http.delete<boolean>(`${this.appConfig.apiBaseUrl}/productos/${id}`);
    }

    masivosProducto(file: File): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this.http.post<any>(`${this.appConfig.apiBaseUrl}/productos/masivos`, formData);
    }
}